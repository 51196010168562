import { H2, P, Button, ButtonLink } from "@upsolve/ui";
import React, { useContext, useState, CSSProperties } from "react";
import { QuestionHeading } from "../../modals/TriageModal";
import { TriageContext, TProduct, TDebtType } from "./state";
import { Accordion } from "./Accordion";

export const DebtQuestionScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }

  const handleSelectDebtType = (selectedDebtType: TDebtType) => {
    context.trackClick(`debtTypeSelected.${selectedDebtType}`);
    context.dispatch({ type: "setDebtType", payload: selectedDebtType });
    context.onNext();
  };

  return (
    <div>
      <H2 style={{ fontFamily: "Castoro", textAlign: "center" }}>Welcome to Upsolve!</H2>
      <P>We’re a nonprofit that helps you get out of debt and take control of your financial future.</P>
      <P>What's the biggest debt challenge you're facing right now?</P>
      <div style={{ marginTop: "20px" }}>
        <Button onClick={() => handleSelectDebtType("consumerDebt")} style={{ marginBottom: "10px" }}>
          Credit Card or Personal Loan
        </Button>
        <Button inverted onClick={() => handleSelectDebtType("mortgage")} style={{ marginBottom: "10px" }}>
          Mortgage
        </Button>
        <Button inverted onClick={() => handleSelectDebtType("carLoan")} style={{ marginBottom: "10px" }}>
          Car Loan
        </Button>
        <Button inverted onClick={() => handleSelectDebtType("medicalDebt")} style={{ marginBottom: "10px" }}>
          Medical Debt
        </Button>
        <Button inverted onClick={() => handleSelectDebtType("taxDebt")} style={{ marginBottom: "10px" }}>
          Tax Debt
        </Button>
      </div>
    </div>
  );
};

export const TaxDebtIframeScreen = () => {
  interface IframeProps {
    src: string;
  }

  const IframeComponent = ({ src }: IframeProps) => {
    const [loading, setLoading] = useState(true);

    const containerStyle: CSSProperties = {
      height: "60vh",
    };

    const iframeStyle: CSSProperties = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
      zIndex: 1,
      display: loading ? "none" : "block",
    };

    const loadingStyle: CSSProperties = {
      display: loading ? "flex" : "none",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: "white",
      zIndex: 0,
    };

    return (
      <div style={containerStyle}>
        <div style={loadingStyle}>Loading...</div>
        <iframe 
          src={src} 
          title="Afterpattern App" 
          style={iframeStyle} 
          onLoad={() => setLoading(false)} 
        />
      </div>
    );
  };

  return (
    <IframeComponent src="https://afterpatternapp.upsolve.org/optima-referral?access_key=CsVZye0ZpfVIViDSURxD33Xh3" />
  );
};

export const OtherDebtsScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }

  const onClickFindALawyerLink = async () => {
    context.trackClick(`clickCta.findLawyer.${context.state.debtType}`);
    context.dispatch({ type: "setProduct", payload: "talkToLawyer" });
    context.onDone();
  };

  const onClickLearnMoreButton = async (url: string) => {
    context.trackClick(`learnMore.${context.state.debtType}`);
    context.onDone();
  };

  interface ProductRenderData {
    header?: string;
    buttonUrl?: string;
    buttonText?: string;
    message?: string;
  }

  const copyIndexedByDebtType: Partial<Record<TDebtType, ProductRenderData>> = {
    carLoan: {
      header: "You have options!",
      buttonUrl: "https://upsolve.org/learn/cant-afford-your-car-payment/",
      buttonText: "Learn More",
      message: "We’re working on app to help you get relief from your car loan.",
    },
    medicalDebt: {
      header: "You have options!",
      buttonUrl: "https://upsolve.org/learn/getting-out-of-medical-debt/",
      buttonText: "Learn More",
      message: "We’re working on app to help you get relief from your medical debt.",
    },
    mortgage: {
      header: "You have options!",
      buttonUrl: "https://upsolve.org/learn/missed-mortgage-payments/",
      buttonText: "Learn More",
      message: "We’re working on app to help you get relief from your mortgage.",
    },
  };

  const productInfo = (copyIndexedByDebtType[context.state.debtType as TDebtType] || {}) as ProductRenderData;

  return (
    <div>
      <H2 style={{ fontFamily: "Castoro" }}>{productInfo.header}</H2>
      <P>{productInfo.message}</P>
      <p>
        In the meantime, you can{" "}
        <a
          href={"https://upsolve.org/find-a-lawyer/"}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClickFindALawyerLink}
        >
          request a free debt evaluation
        </a>{" "}
        from a lawyer and read our step-by-step guide to dealing with your debt.
      </p>
      {productInfo.buttonUrl && (
        <ButtonLink
          href={productInfo.buttonUrl}
          target="_blank"
          onClick={() => onClickLearnMoreButton(productInfo.buttonUrl!)}
        >
          {productInfo.buttonText}
        </ButtonLink>
      )}
    </div>
  );
};

export const ConsumerDebtOverviewScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }
  const handleProductSelection = (selectedProduct: TProduct) => {
    context.trackClick(`productSelected.${selectedProduct}`);
    context.dispatch({ type: "setProduct", payload: selectedProduct });
    context.onNext();
  };

  const onClickExploreBankruptcyButton = async () => {
    context.trackClick(`myUpsolveNavigation`);
    context.trackClick(`productSelected.bankruptcy`);
    context.dispatch({ type: "setProduct", payload: "bankruptcy" });
    context.onDone();
  };

  return (
    <div>
      <H2 style={{ fontFamily: "Castoro" }}>We're here to help</H2>
      <P>Credit card or personal loan debt can be stressful, but we have three free options to help you get relief.</P>
      <ul>
        <li>Our bankruptcy tool can help you eliminate your debt and get a fresh start</li>
        <li>A trusted bankruptcy lawyer can assist you in deciding if you need help</li>
        <li>
          A nonprofit debt specialist can help you make a budget and negotiate with your creditors to pay your debt back
          at a lower interest rate.
        </li>
      </ul>
      <P>What would you like to do?</P>

      <ButtonLink
        href={`https://my.upsolve.org/bankruptcy-screener`}
        target="_blank"
        onClick={onClickExploreBankruptcyButton}
        style={{ marginBottom: "10px" }}
      >
        Use the Upsolve bankruptcy tool
      </ButtonLink>
      <Button inverted onClick={() => handleProductSelection("talkToLawyer")} style={{ marginBottom: "10px" }}>
        Talk with a bankruptcy lawyer
      </Button>
      <Button inverted onClick={() => handleProductSelection("debtSpecialist")} style={{ marginBottom: "10px" }}>
        Talk with a debt specialist
      </Button>
    </div>
  );
};

export const TrustedLawyerScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }

  const onRequestEvaluation = () => {
    context.trackClick(`clickCta.findLawyer`);
    context.onDone();
  };
  return (
    <div>
      <H2 style={{ fontFamily: "Castoro" }}>We can help!</H2>
      <P>Click here to request a free debt evaluation from a trusted lawyer.</P>
      <div>
        <>
          <a href="https://upsolve.org/find-a-lawyer/" target="_blank">
            <Button onClick={onRequestEvaluation} style={{ marginRight: "10px" }}>
              Request free evaluation
            </Button>
          </a>
        </>
      </div>
    </div>
  );
};

export const HasIncomeScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }

  const onAnswer = (answer: boolean) => {
    context.dispatch({ type: "setHasIncome", payload: answer });
    context.trackClick(`hasIncome.${answer}`);
    context.onNext();
  };

  return (
    <div>
      <QuestionHeading style={{ marginBottom: "10px" }}>
        Do you receive any monthly income, including from jobs, friends and family, investments, or government support?
      </QuestionHeading>
      <P>
        This information helps us understand your situation better so that we can suggest options that may be a fit.
      </P>
      <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }}>
        Yes
      </Button>
      <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }}>
        No
      </Button>
    </div>
  );
};

export const DebtSpecialistOfferingScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }

  const onSelectDebtSpecialist = () => {
    context.trackClick(`clickCta.debtSpecialist`);
    context.onDone();
  };

  const onSelectOtherOptions = () => {
    context.onNext();
  };

  return (
    <div style={{ marginTop: "2em" }}>
      <H2 style={{ fontFamily: "Castoro" }}>Talk with a debt specialist about a debt management plan</H2>
      <P>
        Millions of people like you have used a debt management plan to get out of debt. That’s why we've partnered with
        the 25-year industry veteran Cambridge Credit Counseling, a nonprofit debt specialist with an A+ Better Business
        Bureau rating, to help you create a personalized debt management plan.
      </P>
      <Accordion>
        <Accordion.Item>
          <Accordion.Header>What is a debt management plan?</Accordion.Header>
          <Accordion.Body>
            <P>
              A debt management plan (DMP) is a structured program administered by credit counseling agencies to help
              individuals repay unsecured debts by negotiating lower interest rates and waiving fees. It consolidates
              multiple debts into a single monthly payment, making it easier to manage and pay off debts within three to
              five years.
            </P>

            <P>
              If you choose to enroll in a debt management plan with Cambridge Credit Counseling, they will negotiate
              with your creditors to try to lower your interest rates, typically from <b>20-30% down to 8%</b>. This
              could save you an average of <b>$140 per month</b> and allow you to pay off your debt much faster!
            </P>

            <P>
              Most Cambridge clients only experience a credit score drop of around 19 points when they enroll in a debt
              management plan, and they more than make up for that drop during their plan.
            </P>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item>
          <Accordion.Header>Who are they good for?</Accordion.Header>
          <Accordion.Body>
            <>
              Debt management plans are good for people who have a lot of high-interest debts, like credit card
              balances, and need help paying them off. They work well for those who can make regular payments but need
              lower interest rates and fewer fees to manage their debt better.
            </>
            <P>
              These plans are also great for anyone who wants a simpler way to pay off their debt and is ready to stick
              to a plan for a few years to get their finances back on track.
            </P>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item>
          <Accordion.Header>How much do they cost?</Accordion.Header>
          <Accordion.Body>
            There are fees to enroll in a debt management plan based on your budget, but talking to a debt specialist to
            see if it's right for you is free.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* TODO: possibly have calendly embed */}
      <ButtonLink
        href={"https://calendly.com/camupsolve-appt/upsolve-appointment"}
        target="_blank"
        onClick={onSelectDebtSpecialist}
        style={{ marginBottom: "10px" }}
      >
        Schedule a free call
      </ButtonLink>
      <Button inverted onClick={onSelectOtherOptions} style={{ marginBottom: "10px" }}>
        Explore other options
      </Button>
    </div>
  );
};

export const DebtSpecialistNotInterestedScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }

  const onClickExploreBankruptcyButton = () => {
    context.trackClick(`clickCta.bankruptcy`);
    context.onDone();
  };
  return (
    <div>
      <H2 style={{ fontFamily: "Castoro" }}>Bankruptcy can help</H2>
      <P>
        More than 13,000 people who have achieved financial freedom using our free bankruptcy tool. It only takes 5
        minutes to see if it could work for you.
      </P>
      <ButtonLink
        href={`https://my.upsolve.org/bankruptcy-screener`}
        target="_blank"
        onClick={onClickExploreBankruptcyButton}
        style={{ marginBottom: "10px" }}
      >
        Explore bankruptcy
      </ButtonLink>
    </div>
  );
};

export const DebtSpecialistDisqualifyScreen = () => {
  const context = useContext(TriageContext);
  if (!context) {
    return <></>;
  }

  const onClickExploreBankruptcyButton = () => {
    context.trackClick(`clickCta.bankruptcy`);
    context.onDone();
  };
  return (
    <div>
      <H2 style={{ fontFamily: "Castoro" }}>Bankruptcy can help</H2>
      <P>
        It looks like a debt specialist might not be the best fit for you right now since you don't have a steady
        income.
      </P>
      <P>
        But don't worry! You could join over 13,000 people who found financial freedom with our free bankruptcy tool. It
        takes just 5 minutes to see if it could work for you!
      </P>
      <ButtonLink
        href={`https://my.upsolve.org/bankruptcy-screener`}
        target="_blank"
        onClick={onClickExploreBankruptcyButton}
        style={{ marginBottom: "10px" }}
      >
        Explore bankruptcy
      </ButtonLink>
    </div>
  );
};
