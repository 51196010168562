import { IconCaret } from "@upsolve/ui";
import React, { createContext, useContext, useState } from "react";
import styled from "styled-components";

const ToggleContext = createContext(false);

const AccordionStyle = styled.div`
  width: 100%;
  max-width: 633px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  .item {
    display: flex;
    flex-direction: column;
    background: white;
    border-bottom: 0.667px solid var(--Gray-3, #828282);
    width: 100%;
    padding: 10px 14px;
    box-sizing: border-box;
    cursor: pointer;
    &__header {
      display: flex;
      gap: 5px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      svg {
        width: 14px;
        transition: 250ms;
      }
      &.opened {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    &__text {
      ul {
        list-style-type: initial;
      }
    }
  }
`;

export const Accordion = ({ children }: { children: React.ReactNode }) => {
  return <AccordionStyle>{children}</AccordionStyle>;
};

Accordion.Item = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ToggleContext.Provider value={{ setIsOpen, isOpen }}>
      <div className="item" onClick={() => setIsOpen(!isOpen)}>
        {children}
      </div>
    </ToggleContext.Provider>
  );
};

Accordion.Header = ({ children }: { children: React.ReactNode }) => {
  const { isOpen } = useContext(ToggleContext);
  return (
    <div className={`item__header ${isOpen ? "opened" : "closed"}`}>
      {children} <IconCaret />
    </div>
  );
};

Accordion.Body = ({ children }: { children: React.ReactNode }) => {
  const { isOpen } = useContext(ToggleContext);

  return <>{isOpen && <div>{children}</div>}</>;
};
