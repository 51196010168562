import { useEffect, useState } from "react";
import { CTATarget, getCTARenderedCopy, getCTATrackingTarget, getCTAURL } from "../CTA/getCTAData";
import { H4, P, theme } from "@upsolve/ui";
import { MyUpsolveButtonLink } from "../Links/MyUpsolveLink";
import styled from "styled-components";
import trackComponentAction from "../analytics/trackComponentAction";
import trackComponentImpression from "../analytics/trackComponentImpression";
import React from "react";
import ImageInContext from "../Media/ImageInContext";
import { ProductSelectorModal } from "../Forms/ProductSelectorModal";
import useModal from "../modals/useModal";

const CTAtoMobilePopUpImage: { [key in CTATarget]: { src: string; alt: string } } = {
  [CTATarget.AJM]: { src: "/images/AJMGraphic.png", alt: "phone demo of talking to a Justice Advocate" },
  [CTATarget.SCREENER]: {
    src: "/images/phone-demo-short.png",
    alt: "phone demo of filling bankruptcy forms",
  },
  [CTATarget.STUDENT_LOANS]: { src: "/images/StudentLoanCTAGraphic.gif", alt: "Student Loans Demo" },
  [CTATarget.DEBT_TRIAGE]: {
    src: "/images/debt_triage_image.png",
    alt: "phone demo of filling bankruptcy forms",
  },
};

const CTAMobilePopUpImage = ({ target = CTATarget.SCREENER }: { target?: CTATarget }) => {
  const { src, alt } = CTAtoMobilePopUpImage[target];
  if (src) {
    return <ImageInContext alt={alt} src={src} />;
  } else {
    return <div></div>;
  }
};

const popUpTrackingProps = {
  componentName: "NavPopUp",
  componentVersion: "1",
};

type NavPopUpProps = {
  trackingPageContext: any;
  target: CTATarget;
};

/**
 * NavPopUp
 * - v0: Popup for nav
 * - v1: Include Modal support
 *
 * @component
 * @version 1
 */
export const NavPopUp = (props: NavPopUpProps) => {
  const [hasFixedFooter, setHasFixedFooter] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const modal = useModal();
  const ctaCopy = getCTARenderedCopy(props.target);

  const CTAModal: { [key in CTATarget]: { modal: any } } = {
    [CTATarget.DEBT_TRIAGE]: {
      modal: (
        <ProductSelectorModal onHide={() => modal.setIsOpen(false)} trackingPageContext={props.trackingPageContext} />
      ),
    },
    [CTATarget.AJM]: { modal: null },
    [CTATarget.SCREENER]: { modal: null },
    [CTATarget.STUDENT_LOANS]: { modal: null },
  };

  function close() {
    trackComponentAction({
      ...popUpTrackingProps,
      actionId: "navPopUp.close",
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
    setIsShowing(false);
  }

  useEffect(() => {
    setHasFixedFooter(window.innerWidth < Number(theme.breakpoints[750].replace("px", "")));
    setTimeout(() => {
      if (typeof window === "undefined") return;
      const timeLastShown = localStorage.getItem("Upsolve.navPopUpShown")
        ? Number(localStorage.getItem("Upsolve.navPopUpShown"))
        : null;
      // Show no more than once every two minutes
      if (!timeLastShown || new Date().getTime() - 120 * 1000 > timeLastShown) {
        setIsShowing(true);
        trackComponentImpression({
          ...popUpTrackingProps,
          pageContext: props.trackingPageContext,
        });
        localStorage.setItem("Upsolve.navPopUpShown", new Date().getTime().toString());
      }
    }, 8000);
  }, []);

  const handleClick = () => {
    const targetUrl = getCTAURL(props.target);

    if (CTAModal[props.target] && CTAModal[props.target].modal) {
      // Open modal
      modal.setIsOpen(true, CTAModal[props.target].modal);
    } else if (targetUrl) {
      // Navigate to href
      window.location.href = targetUrl;
    }
    trackComponentAction({
      ...popUpTrackingProps,
      actionId: `navPopUp.${getCTATrackingTarget(props.target)}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
  };

  return (
    <>
      {hasFixedFooter && isShowing && (
        <StyledNavPopUp onClick={(ev) => ev.preventDefault()} target={props.target}>
          <div className="hint__demo" onClick={close}>
            <CTAMobilePopUpImage target={props.target} />
          </div>
          <div className="hint__panel">
            <H4>{ctaCopy.title}</H4>
            <P>{ctaCopy.description}</P>
            <MyUpsolveButtonLink size="lg" width="100%" onClick={handleClick}>
              {ctaCopy.buttonText}
            </MyUpsolveButtonLink>
            <u onClick={close}>No Thanks</u>
          </div>
          <div className="hint__background" onClick={close} />
        </StyledNavPopUp>
      )}
      {modal.isOpen && modal.component} {/* Render modal separately */}
    </>
  );
};

type StyledNavPopUpProps = {
  target: CTATarget;
};
const StyledNavPopUp = styled.div<StyledNavPopUpProps>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 64px;
  z-index: 997;
  display: flex;
  flex-direction: column;
  align-items: center;
  .hint__demo {
    padding: ${(props) => (props.target === CTATarget.AJM ? "0 2em" : "0 5em")};
    margin-bottom: -3.5em;
    max-width: 444px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .hint__panel {
    box-shadow: 0 4px 40px 0 rgba(0, 0, 25, 0.5);
    margin: 3em 2.5em;
    height: auto;
    background: white;
    border-radius: 8px;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 555px;
    h4 {
      border-bottom: 2px solid ${(props) => props.theme.colors.white[300]};
      padding: 0 0 12px;
    }
    u {
      text-align: center;
      margin-top: 8px;
      margin-bottom: -4px;
      z-index: 95;
      &:hover {
        cursor: pointer;
      }
    }
    p {
      font-size: 15px;
      line-height: 140%;
      letter-spacing: -0.1px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[300]}) {
      margin: 3em 1em;
    }
    &:after {
      content: "";
      height: 40px;
      width: 40px;
      position: absolute;
      bottom: -15px;
      transform: rotate(45deg);
      background: white;
    }
  }
  .hint__background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
  }
  .hint__demo,
  .hint__panel {
    z-index: 100;
  }
  .hint__background {
    z-index: 99;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[750]}) {
    display: none;
  }
`;

export default NavPopUp;
