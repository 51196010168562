import React from "react";
import styled from "styled-components";

type TProgressBarProps = {
  progress: number;
  max: number;
  min?: number;
};

export const ProgressBar: React.FC<TProgressBarProps> = (props) => (
  <ProgressBarWrapper {...props}>
    <span />
  </ProgressBarWrapper>
);

export const ProgressBarWrapper = styled.div<TProgressBarProps>`
  width: 100%;
  height: 4px;
  background: ${(props) => props.theme.colors.white["500"]};
  & > span {
    animation-timing-function: ease;
    transition: width 1000ms;
    display: block;
    width: ${(props) => `${((Math.max(props.min || 0, props.progress) || 10) / (props.max || 100)) * 100}%`};
    height: 100%;
    background: ${(props) => props.theme.colors.green["500"]};
    border-raidus: 0 2px 2px 0;
    display: flex;
    justify-content: flex-end;
  }
  & > span::after {
    content: "";
    display: block;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background: ${(props) => props.theme.colors.green["100"]};
    position: relative;
    top: -3px;
    border-radius: 5px;
    right: 0;
  }
`;
