import React, { Fragment, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { theme } from "@upsolve/ui";

import { queryClient } from "../queries/queryClient";
import config from "../../../site-config";
import GlobalStyle from "../GlobalStyle";
import Hotjar from "@hotjar/browser";

//initializing here because for whatever reason it isn't working in the html.jsx file
//IMPORTANT: they seem to reject requests from http web sessions, so note that
//things do not work locally which is good because we don't want local data, but
//annoying for testing
const SITE_ID = 5072883;
const HOTJAR_VERSION = 6;
Hotjar.init(SITE_ID, HOTJAR_VERSION);

const TopLevelLayout = (props) => {
  useEffect(() => console.log(upsolveConsoleLog), []);
  return (
    <Fragment>
      <GlobalStyle />
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </QueryClientProvider>
    </Fragment>
  );
};

const upsolveConsoleLog = `
██████████████████████████████████████████████████████████

██╗   ██╗██████╗ ███████╗ ██████╗ ██╗    ██╗   ██╗███████╗
██║   ██║██╔══██╗██╔════╝██╔═══██╗██║    ██║   ██║██╔════╝
██║   ██║██████╔╝███████╗██║   ██║██║    ██║   ██║█████╗
██║   ██║██╔═══╝ ╚════██║██║   ██║██║    ╚██╗ ██╔╝██╔══╝
╚██████╔╝██║     ███████║╚██████╔╝███████╗╚████╔╝ ███████╗
 ╚═════╝ ╚═╝     ╚══════╝ ╚═════╝ ╚══════╝ ╚═══╝  ╚══════╝

██████████████████████████████████████████████████████████

👋 Yo!
👩‍💻 Would you like to:

⚬ Improve access to an under-utilized social safety net?
⚬ Create a countering force to predatory lenders?
⚬ Change the perception of what a nonprofit can do?
⚬ Get in a good kind of a trouble?

📬 Email: dev@upsolve.org

██████████████████████████████████████████████████████████
`;

export default TopLevelLayout;
