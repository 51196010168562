import { createContext } from "react";

export type TDebtType = "consumerDebt" | "mortgage" | "carLoan" | "medicalDebt" | "taxDebt";

export type TProduct = "talkToLawyer" | "bankruptcy" | "debtSpecialist";

export type TDebtTriageContext = null | {
  state: TTriageState;
  dispatch: (action: TAction) => void;
  onNext: () => void;
  onDone: () => void;
  trackClick: (actionId: string) => void;
};

export const TriageContext = createContext<TDebtTriageContext>(null);

export type TTriageState = {
  product: TProduct | undefined;
  debtType: TDebtType | undefined;
  hasIncome: boolean | undefined;
  hasInterestInDebtSpecialist: boolean | undefined;
};

export type TAction =
  | { type: "setProduct"; payload: TProduct }
  | { type: "setDebtType"; payload: TDebtType }
  | { type: "setHasInterestInDebtSpecialist"; payload: boolean }
  | { type: "setHasIncome"; payload: boolean };

export const initialState: TTriageState = {
  product: undefined,
  debtType: undefined,
  hasIncome: undefined,
  hasInterestInDebtSpecialist: undefined,
};

export function reducer(state: TTriageState, action: TAction): TTriageState {
  switch (action.type) {
    case "setProduct":
      return { ...state, product: action.payload };
    case "setDebtType":
      return { ...state, debtType: action.payload };
    case "setHasIncome":
      return { ...state, hasIncome: action.payload };
    case "setHasInterestInDebtSpecialist":
      return { ...state, hasInterestInDebtSpecialist: action.payload };
    default:
      throw new Error("Unhandled action type");
  }
}
